<template lang="pug">
div
  TableV2(
    :actions="actions"
    :headers="headers"
    :isLoading="isLoading"
    :items="items.results.data"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="getData"
    :total="items.results"
    isHiddenTableSearch
  )

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.info',
          action: (item) => {
            const routeData = this.$router.resolve({ name: 'distribution-nostrification-report-crewing', params: { ...this.$route.params }, query: { ...this.$route.query, crewing_group: item.group } })
            window.open(routeData.href, '_blank')
          },
          color: 'blue',
          name: 'mdi-information-outline'
        }
      ],
      headers: [
        { value: 'group', text: this.$t('group'), title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), total: { global: 'global_form2_distribution', page: 'page_form2_distribution' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), total: { global: 'global_form2_profit', page: 'global_form2_profit' }, sortable: false },
        { value: 'event', text: this.$t('go'), class: 'mw-0', sortable: false }],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.distributionNostrificationGroup
    })
  },
  methods: {
    ...mapActions(['getDistributionNostrificationGroup']),
    async getData (params) {
      this.isLoading = true
      const { typeDocument } = this.$route.params
      await this.getDistributionNostrificationGroup({ typeDocument, params })
      this.isLoading = false
    }
  }
}
</script>
